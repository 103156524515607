<template>
  <modal-wrapper
    :id="id"
    hide-footer
    size="lg"
  >
    <template #title>
        Invito utente a collaborare
    </template>
    <template #body>
        <user-invite-wizard :on-close-modal="closeModal" />
    </template>
  </modal-wrapper>
</template>

<script>
const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');
const UserInviteWizard = () => import('@/components/wizards/UserInviteWizard.vue');

export default {
  name: 'UserInviteModal',
  components: { UserInviteWizard, ModalWrapper },
  props: {
    id: String,
    onCloseModal: Function,
  },
  methods: {
    closeModal() {
      if (this.onCloseModal) this.onCloseModal();
      this.$bvModal.hide(this.id);
    },
  },
};
</script>

<style scoped>

</style>
